import React, { useState } from "react";
import ProjectCard from "../ProjectDetails/ProjectCards";
import { project } from "../../data";
import { Reveal } from "../Reveal";

const Projects = ({
  openModal,
  setOpenModal,
}) => {
  const [toggle, setToggle] = useState("all");
  return (
    <div
      className="relative h-full mt-28"
      id="projects"
    >
      <div className="pb-5 h-full">
        <div className="h-full flex flex-col m-auto w-4/5 gap-4 items-center">
          <div className="mr-auto flex flex-col w-full">
            <Reveal>
              <h2 className="text-5xl sm:text-7xl md:mr-auto md:text-8xl font-extrabold">
                &#8826;projects&#8827;
              </h2>
            </Reveal>
            <Reveal>
              <p
                className="text-lg sm:text-xl md:text-2xl font-light md:mr-auto my-5"
                style={{ color: "#ffffffaa" }}
              >
                I have worked on a wide range of
                projects. Here are some of my
                projects.
              </p>
            </Reveal>
          </div>
          <div className="flex gap-2 mt-6">
            <button
              className={`border-2 border-primary text-primary text-sm md:text-lg font-medium py-1 px-2 lg:py-2 lg:px-4 rounded-full transition duration-300 ease-in-out ${
                toggle === "all"
                  ? "bg-primary bg-opacity-20"
                  : ""
              }`}
              onClick={() => setToggle("all")}
            >
              All
            </button>
            <div className="border-l-2 border-primary"></div>
            <button
              className={`border-2 border-primary text-primary text-sm md:text-lg font-medium py-1 px-2 lg:py-2 lg:px-4 rounded-full transition duration-300 ease-in-out ${
                toggle === "web app"
                  ? "bg-primary bg-opacity-20"
                  : ""
              }`}
              onClick={() => setToggle("web app")}
            >
              WEB APP
            </button>
            <div className="border-l-2 border-primary"></div>
            <button
              className={`border-2 border-primary text-primary text-sm md:text-lg font-medium py-1 px-2 lg:py-2 lg:px-4 rounded-full transition duration-300 ease-in-out ${
                toggle === "machine learning"
                  ? "bg-primary bg-opacity-20"
                  : ""
              }`}
              onClick={() =>
                setToggle("machine learning")
              }
            >
              MACHINE LEARNING
            </button>
          </div>
          <div className="flex flex-row w-full justify-center items-center flex-wrap mt-6 gap-4">
            {toggle === "all" &&
              project.map((project, index) => (
                <ProjectCard
                  key={index}
                  project={project}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              ))}
            {project
              .filter(
                (item) => item.category === toggle
              )
              .map((project, index) => (
                <ProjectCard
                  key={index}
                  project={project}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
