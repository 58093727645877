import { TypeAnimation } from "react-type-animation";
import { Reveal } from "./Reveal";
import resumepdf from "../assets/Dev Panchal Resume.pdf";
import { useState } from "react";

const textVariants = {
  scrollButton: {
    opacity: 0,
    y: 10,
    transition: {
      duration: 2,
      repeat: Infinity,
    },
  },
};

export const About = () => {
  const [activeTab, setActiveTab] = useState(
    "tabs-education"
  );
  return (
    <div
      className="relative h-full mt-18"
      id="about"
    >
      <div className="pb-5 h-full">
        <div className=" w-4/5 flex flex-col-reverse items-center h-full md:items-start md:flex-row m-auto gap-5 md:h-full">
          <div className="h-full flex-1 md:flex flex-col gap-1 md:gap-2 md:mt-20 xl:mt-28">
            <Reveal>
              <h1
                className="text-5xl sm:text-7xl mr-auto md:mr-auto md:text-8xl font-extrabold"
                style={{ color: "#ffffff" }}
                variants={textVariants}
              >
                &#8826;about&#8827;
                {/* about */}
              </h1>
            </Reveal>
            <Reveal>
              <TypeAnimation
                sequence={[
                  "Coder",
                  1000,
                  "Developer",
                  1000,
                  "Globetrotter",
                  1000,
                  "Designer",
                  1000,
                ]}
                wrapper="span"
                speed={30}
                className="text-2xl sm:text-3xl md:text-4xl font-bold max-w-fit md:mr-auto"
                style={{
                  color: "#ffffff",
                  textShadow:
                    "3px 3px 0 #001524, 4px 4px 0 #ffffff",
                }}
                repeat={Infinity}
              />
            </Reveal>
            <Reveal>
              <h2
                className="text-lg sm:text-xl md:text-2xl font-light"
                style={{ color: "#ffffffaa" }}
              >
                I'm an aspiring web developer
                hailing from the vibrant city
                Ahmedabad, India.
              </h2>
            </Reveal>
            <Reveal>
              <p className="text-xs sm:text-sm md:text-md font-medium">
                As a fresh talent in the field, I
                bring a passion for coding and a
                keen interest in crafting
                innovative websites.Beyond the
                world of web development, I am an
                avid traveler, finding inspiration
                in exploring new places and
                cultures.
              </p>
            </Reveal>
            <img
              src="/images/signature.png"
              className="w-16 mt-2 sm:w-20 sm:mt-3 md:w-24 md:mt-4 ml-auto"
              alt=""
            />
            <a
              href={resumepdf}
              download={"Dev Panchal Resume"}
              target="_blank"
              rel="noreferrer"
            >
              <button className="my-5 mr-auto p-4 border-2 rounded-lg border-slate-500 text-[14px] md:text-[16px] lg:text-xl">
                Download my Resume
              </button>
            </a>
          </div>
          <div className="flex-1 md:flex items-center justify-center h-[300px] md:h-[400px] lg:h-[450px] overflow-hidden"
          style={{ borderRadius:"100%"}}
          >
            <img
              src="/images/hero.png"
              className="w-full h-full object-cover"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="pb-5 mt-20">
        <div
          className="md:flex my-3 w-3/4 m-auto"
          key={activeTab}
        >
          <ul
            className="mr-4 flex list-none md:flex-col flex-wrap md:justify-start pl-0"
            role="tablist"
          >
            {/* <li
          role="presentation"
          className="flex-grow md:flex-grow-0 text-center"
        >
          <a
            role="tab"
            tabIndex={0}
            onClick={(e) => {
              setActiveTab("tabs-profile");
            }}
            className={`my-2 block border-2  ${
              activeTab == "tabs-profile"
                ? "border-inherit"
                : "border-transparent"
            } rounded-md px-7 pb-3.5 pt-4 text-xs font-semibold uppercase leading-tight text-slate-200 hover:isolate hover:bg-slate-100 focus:isolate focus:border-transparent dark:text-slate-400 dark:hover:bg-transparent`}
            aria-controls="tabs-profile"
            aria-selected={activeTab === "tabs-profile"}
          >
            Profile
          </a>
        </li> */}
            <li
              role="presentation"
              className="flex-grow md:flex-grow-0 text-center"
            >
              <a
                role="tab"
                tabIndex={0}
                onClick={(e) => {
                  setActiveTab("tabs-education");
                }}
                className={`my-2 block border-2 cursor-pointer ${
                  activeTab == "tabs-education"
                    ? "border-inherit"
                    : "border-transparent"
                } rounded-md px-7 pb-3.5 pt-4 text-xs font-semibold uppercase leading-tight text-white hover:isolate hover:bg-slate-100 focus:isolate focus:border-transparent dark:text-slate-400 dark:hover:bg-transparent`}
                aria-controls="tabs-education"
                aria-selected={
                  activeTab === "tabs-education"
                }
              >
                Education
              </a>
            </li>
            <li
              role="presentation"
              className="flex-grow md:flex-grow-0 text-center"
            >
              <a
                role="tab"
                tabIndex={0}
                onClick={(e) => {
                  setActiveTab("tabs-work");
                }}
                className={`my-2 block border-2 cursor-pointer ${
                  activeTab == "tabs-work"
                    ? "border-inherit"
                    : "border-transparent"
                } rounded-md px-7 pb-3.5 pt-4 text-xs font-semibold uppercase leading-tight text-white hover:isolate hover:bg-slate-100 focus:isolate focus:border-transparent dark:text-slate-400 dark:hover:bg-transparent`}
                aria-controls="tabs-work"
                aria-selected={
                  activeTab === "tabs-work"
                }
              >
                Experience
              </a>
            </li>
            <li
              role="presentation"
              className="flex-grow md:flex-grow-0 text-center"
            >
              <a
                role="tab"
                tabIndex={0}
                onClick={(e) => {
                  setActiveTab(
                    "tabs-achievements"
                  );
                }}
                className={`my-2 block border-2 cursor-pointer ${
                  activeTab == "tabs-achievements"
                    ? "border-inherit"
                    : "border-transparent"
                } rounded-md px-7 pb-3.5 pt-4 text-xs font-semibold uppercase leading-tight text-white hover:isolate hover:bg-slate-100 focus:isolate focus:border-transparent dark:text-slate-400 dark:hover:bg-transparent`}
                aria-controls="tabs-achievements"
                aria-selected={
                  activeTab ===
                  "tabs-achievements"
                }
              >
                Certifications
              </a>
            </li>
          </ul>

          <div className="my-2 p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full">
            {/* {activeTab === "tabs-profile" && (
          <div
            className="opacity-100 transition-opacity duration-150 ease-linear"
            id="tabs-profile"
            role="tabpanel"
            aria-labelledby="tabs-profile-tab"
          >
            Polyglot developer, specializing in full-stack proficiency.
            Passionate about crafting practical solutions to real-world
            problems, I seamlessly navigate between diverse technologies to
            deliver high-impact, user-centric applications. My track record
            reflects a commitment to excellence in project execution, making me
            a valuable asset to dynamic teams seeking innovative and efficient
            development. I primarily follow MVC Architecture for my
            applications, but I can easily adjust to other architectures. Stacks
            I can skillfully work on - MERN, PERN, SERN, LAMP.
          </div>
        )} */}
            {activeTab === "tabs-education" && (
              <div
                className="opacity-100 transition-opacity duration-150 ease-linear"
                id="tabs-education"
                role="tabpanel"
                aria-labelledby="tabs-education-tab"
              >
                <ol className="relative border-s border-gray-200 dark:border-gray-700">
                  <li className="mb-5 ms-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <Reveal>
                      <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        July 2021 - Present
                      </time>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                        BTech in Computer Science
                        & Engineering
                      </h3>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-sm my-1 font-normal font-acumin text-gray-900 dark:text-white">
                        <a
                          href="https://pdpu.ac.in"
                          target="_blank"
                          className="flex gap-1 items-center"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            className="h-4 w-4"
                            fill="none"
                          >
                            <g opacity="0.5">
                              <path
                                d="M19.7388 4.26118C17.0572 1.57961 12.7095 1.57961 10.0279 4.26118L9.30707 4.98203C9.01418 5.27492 9.01418 5.7498 9.30707 6.04269C9.59997 6.33558 10.0748 6.33558 10.3677 6.04269L11.0886 5.32184C13.1844 3.22605 16.5823 3.22605 18.6781 5.32184C20.7739 7.41763 20.7739 10.8156 18.6781 12.9114L17.9573 13.6322C17.6644 13.9251 17.6644 14.4 17.9573 14.6929C18.2502 14.9858 18.725 14.9858 19.0179 14.6929L19.7388 13.972C22.4203 11.2905 22.4203 6.94276 19.7388 4.26118Z"
                                fill="#1C274C"
                              />
                              <path
                                d="M6.04269 9.30707C6.33558 9.59997 6.33558 10.0748 6.04269 10.3677L5.32184 11.0886C3.22605 13.1844 3.22605 16.5823 5.32184 18.6781C7.41763 20.7739 10.8156 20.7739 12.9114 18.6781L13.6322 17.9573C13.9251 17.6644 14.4 17.6644 14.6929 17.9573C14.9858 18.2501 14.9858 18.725 14.6929 19.0179L13.972 19.7388C11.2905 22.4203 6.94276 22.4203 4.26118 19.7388C1.57961 17.0572 1.57961 12.7095 4.26118 10.0279L4.98203 9.30707C5.27492 9.01418 5.7498 9.01418 6.04269 9.30707Z"
                                fill="#1C274C"
                              />
                            </g>
                            <path
                              d="M14.6933 9.30707C14.9862 9.59997 14.9862 10.0748 14.6933 10.3677L10.3682 14.6928C10.0753 14.9857 9.60045 14.9857 9.30756 14.6928C9.01467 14.3999 9.01467 13.9251 9.30756 13.6322L13.6327 9.30707C13.9255 9.01418 14.4004 9.01418 14.6933 9.30707Z"
                              fill="#1C274C"
                            />
                          </svg>
                          Pandit Deendayal Energy
                          University, Gandhinagar
                        </a>
                      </h3>
                    </Reveal>
                    <Reveal>
                      <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                        <b>9.6</b> CGPA (Till 6th
                        Semester)
                        <br /> Learnings: DSA
                        (Data Structure and
                        Algorithm), Discrete
                        Mathematics, OOPs with
                        Java, Design and Analysis
                        of Algorithm, Design
                        Pattern,Database
                        Management System (DBMS),
                        Operating System (OS),
                        Theory of Computation
                        (TOC), Advance Python,
                        Computer Networks,
                        Cryptography and
                        Information Security, Web
                        Technology, Software
                        Engineering, System
                        Software & Compiler
                        Design, Artifical
                        Intelligence (Ai), Cloud
                        Computing, Big Data
                        Analytics, Industry 4.0,
                        Accounting.
                      </p>
                    </Reveal>
                    {/* <a
                  href="#"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                >
                  Learn more{" "}
                  <svg
                    className="w-3 h-3 ms-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </a> */}
                  </li>
                  <li className="mb-5 ms-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <Reveal>
                      <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        May 2021
                      </time>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                        HSC (12th Grade)
                      </h3>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-sm my-1 font-normal font-acumin text-gray-900 dark:text-white">
                        <a
                          href="https://vpmp.ac.in/"
                          target="_blank"
                          className="flex gap-1 items-center"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            className="h-4 w-4"
                            fill="none"
                          >
                            <g opacity="0.5">
                              <path
                                d="M19.7388 4.26118C17.0572 1.57961 12.7095 1.57961 10.0279 4.26118L9.30707 4.98203C9.01418 5.27492 9.01418 5.7498 9.30707 6.04269C9.59997 6.33558 10.0748 6.33558 10.3677 6.04269L11.0886 5.32184C13.1844 3.22605 16.5823 3.22605 18.6781 5.32184C20.7739 7.41763 20.7739 10.8156 18.6781 12.9114L17.9573 13.6322C17.6644 13.9251 17.6644 14.4 17.9573 14.6929C18.2502 14.9858 18.725 14.9858 19.0179 14.6929L19.7388 13.972C22.4203 11.2905 22.4203 6.94276 19.7388 4.26118Z"
                                fill="#1C274C"
                              />
                              <path
                                d="M6.04269 9.30707C6.33558 9.59997 6.33558 10.0748 6.04269 10.3677L5.32184 11.0886C3.22605 13.1844 3.22605 16.5823 5.32184 18.6781C7.41763 20.7739 10.8156 20.7739 12.9114 18.6781L13.6322 17.9573C13.9251 17.6644 14.4 17.6644 14.6929 17.9573C14.9858 18.2501 14.9858 18.725 14.6929 19.0179L13.972 19.7388C11.2905 22.4203 6.94276 22.4203 4.26118 19.7388C1.57961 17.0572 1.57961 12.7095 4.26118 10.0279L4.98203 9.30707C5.27492 9.01418 5.7498 9.01418 6.04269 9.30707Z"
                                fill="#1C274C"
                              />
                            </g>
                            <path
                              d="M14.6933 9.30707C14.9862 9.59997 14.9862 10.0748 14.6933 10.3677L10.3682 14.6928C10.0753 14.9857 9.60045 14.9857 9.30756 14.6928C9.01467 14.3999 9.01467 13.9251 9.30756 13.6322L13.6327 9.30707C13.9255 9.01418 14.4004 9.01418 14.6933 9.30707Z"
                              fill="#1C274C"
                            />
                          </svg>
                          H.B. Kapadia New High
                          School, Ahmedabad
                        </a>
                      </h3>
                    </Reveal>
                    <Reveal>
                      <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Higher Secondary Education
                        passed 12th grade with
                        89%.
                      </p>
                    </Reveal>
                  </li>
                  <li className="ms-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <Reveal>
                      <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        May 2019
                      </time>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                        SSC (10th Grade)
                      </h3>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-sm my-1 font-normal font-acumin text-gray-900 dark:text-white">
                        <a
                          href="https://amrutschool.edu.in/"
                          target="_blank"
                          className="flex gap-1 items-center"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            className="h-4 w-4"
                            fill="none"
                          >
                            <g opacity="0.5">
                              <path
                                d="M19.7388 4.26118C17.0572 1.57961 12.7095 1.57961 10.0279 4.26118L9.30707 4.98203C9.01418 5.27492 9.01418 5.7498 9.30707 6.04269C9.59997 6.33558 10.0748 6.33558 10.3677 6.04269L11.0886 5.32184C13.1844 3.22605 16.5823 3.22605 18.6781 5.32184C20.7739 7.41763 20.7739 10.8156 18.6781 12.9114L17.9573 13.6322C17.6644 13.9251 17.6644 14.4 17.9573 14.6929C18.2502 14.9858 18.725 14.9858 19.0179 14.6929L19.7388 13.972C22.4203 11.2905 22.4203 6.94276 19.7388 4.26118Z"
                                fill="#1C274C"
                              />
                              <path
                                d="M6.04269 9.30707C6.33558 9.59997 6.33558 10.0748 6.04269 10.3677L5.32184 11.0886C3.22605 13.1844 3.22605 16.5823 5.32184 18.6781C7.41763 20.7739 10.8156 20.7739 12.9114 18.6781L13.6322 17.9573C13.9251 17.6644 14.4 17.6644 14.6929 17.9573C14.9858 18.2501 14.9858 18.725 14.6929 19.0179L13.972 19.7388C11.2905 22.4203 6.94276 22.4203 4.26118 19.7388C1.57961 17.0572 1.57961 12.7095 4.26118 10.0279L4.98203 9.30707C5.27492 9.01418 5.7498 9.01418 6.04269 9.30707Z"
                                fill="#1C274C"
                              />
                            </g>
                            <path
                              d="M14.6933 9.30707C14.9862 9.59997 14.9862 10.0748 14.6933 10.3677L10.3682 14.6928C10.0753 14.9857 9.60045 14.9857 9.30756 14.6928C9.01467 14.3999 9.01467 13.9251 9.30756 13.6322L13.6327 9.30707C13.9255 9.01418 14.4004 9.01418 14.6933 9.30707Z"
                              fill="#1C274C"
                            />
                          </svg>
                          Asia English School,
                          Ahmedabad
                        </a>
                      </h3>
                    </Reveal>
                    <Reveal>
                      <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                        High Schooling passed 10th
                        grade with 89%.
                      </p>
                    </Reveal>
                  </li>
                </ol>
              </div>
            )}
            {activeTab === "tabs-work" && (
              <div
                className="opacity-100 transition-opacity duration-150 ease-linear"
                id="tabs-messages"
                role="tabpanel"
                aria-labelledby="tabs-work-tab"
              >
                <ol className="relative border-s border-gray-200 dark:border-gray-700">
                  <li className="mb-5 ms-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <Reveal>
                      <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        May 2022 - June 2022
                      </time>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                        Volunteered as an Intern
                        at Tresna Foundation.
                      </h3>
                    </Reveal>
                    <Reveal>
                      <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Led daily teaching
                        sessions at Tresna
                        Foundation for
                        underprivileged students,
                        focusing on essential
                        computer skills and
                        Microsoft tools like Word,
                        PowerPoint, and Excel.
                        Equipped students with
                        crucial digital literacy
                        in a tech-driven world.
                      </p>
                    </Reveal>
                    {/* <a
                  href="#"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
                >
                  Learn more{" "}
                  <svg
                    className="w-3 h-3 ms-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </a> */}
                  </li>
                  <li className="mb-5 ms-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <Reveal>
                      <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        June 2023
                      </time>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                        Solving for India
                        Hackathon
                      </h3>
                    </Reveal>
                    <Reveal>
                      <small>GeeksforGeeks</small>
                    </Reveal>
                    <Reveal>
                      <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Selected in top 70 teams
                        all over India.
                      </p>
                    </Reveal>
                  </li>
                  <li className="mb-5 ms-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                    <Reveal>
                      <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        October 2023
                      </time>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                        Smart India Hackathon
                      </h3>
                    </Reveal>
                    <Reveal>
                      <small>PDEU</small>
                    </Reveal>
                    <Reveal>
                      <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                        University round Qualified
                      </p>
                    </Reveal>
                  </li>
                  {/* <li className="ms-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                  April 2022
                </time>
                <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                  E-Commerce UI code in Tailwind CSS
                </h3>
                <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                  Get started with dozens of web components and interactive
                  elements built on top of Tailwind CSS.
                </p>
              </li> */}
                </ol>
              </div>
            )}
            {activeTab ===
              "tabs-achievements" && (
              <div
                className="opacity-100 transition-opacity duration-150 ease-linear"
                id="tabs-contact"
                role="tabpanel"
                aria-labelledby="tabs-achievements-tab"
              >
                <ul className="list-circle pl-5 flex flex-col gap-3">
                  <li>
                    <Reveal>
                      <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        November 2023
                      </time>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                        Google Cloud Computing
                        Foundations
                      </h3>
                    </Reveal>
                    <Reveal>
                      <small>Google Cloud</small>
                    </Reveal>
                  </li>
                  <li>
                    <Reveal>
                      <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        November 2023
                      </time>
                    </Reveal>
                    <Reveal>
                      <h3 className="text-md font-semibold text-gray-900 dark:text-white">
                        Level 3 Gen-AI Prompt
                        Engineering
                      </h3>
                    </Reveal>
                    <Reveal>
                      <small>Google Cloud</small>
                    </Reveal>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <div className="pb-5 h-full">
  <div className="h-full flex flex-row m-auto w-4/5 gap-16">
    <div className="md:flex flex-col gap-1 md:gap-2 h-full mt-20">
      <Reveal>
        <h1 className="text-5xl sm:text-7xl md:mr-auto md:text-6xl font-extrabold">
          skills & experince
        </h1>
      </Reveal>
      <Reveal>
        <h2
          className="text-lg sm:text-xl md:text-2xl font-light md:mr-auto my-5"
          style={{ color: "#ffffffaa" }}
        >
          Dynamic skill set covering the
          wide spectrum of web development
        </h2>
      </Reveal>

      <div className="flex flex-col lg:flex-row w-full gap-10 my-5 h-fit">
        <div
          className="flex flex-col gap-5 flex-1 items-center justify-start p-8 h-fit rounded-lg"
          style={{
            boxShadow:
              "0px 0px 6px #cbd5e1",
          }}
        >
          <Reveal>
            <h1 className="text-2xl md:text-3xl">
              Frontend Designing
            </h1>
          </Reveal>
          <div className="flex flex-row gap-3 md:gap-6 flex-wrap">
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(247, 133, 2)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/html.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(5, 93, 235)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/css.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(235, 235, 5)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/js.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent flex justify-center items-center"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(5, 200, 235)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/react.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent flex justify-center items-center"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(0, 0, 0)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/nextjs.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent flex justify-center items-center"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(5, 219, 235)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/tailwindcss.png"
                alt=""
              />
            </motion.div>
          </div>
        </div>
        <div
          className="flex flex-col flex-1 gap-5 shadow-xl items-center justify-start p-8 h-fit rounded-lg"
          style={{
            boxShadow:
              "0px 0px 6px #cbd5e1",
          }}
        >
          <Reveal>
            <h1 className="text-2xl md:text-3xl">
              Server Side
            </h1>
          </Reveal>
          <div className="flex flex-row gap-3 md:gap-6 flex-wrap">
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent flex justify-center items-center"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(5, 235, 85)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/node.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent flex justify-center items-center"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(1, 71, 27)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/django.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(2, 224, 69)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/mongodb.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent flex justify-center items-center"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(2, 143, 224)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/mysql.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent flex justify-center items-center"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(2, 143, 224)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/postresSql.png"
                alt=""
              />
            </motion.div>
          </div>
        </div>
        <div
          className="flex flex-col flex-1 gap-5 shadow-xl items-center justify-start p-8 h-fit rounded-lg"
          style={{
            boxShadow:
              "0px 0px 6px #cbd5e1",
          }}
        >
          <Reveal>
            <h1 className="text-2xl md:text-3xl">
              Programming Laguages
            </h1>
          </Reveal>
          <div className="flex flex-row gap-3 md:gap-5 flex-wrap">
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent flex justify-center items-center"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(0, 0, 255)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/c.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(2, 143, 224)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="skills/c++.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent flex justify-center items-center"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(255, 0, 0)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/java.png"
                alt=""
              />
            </motion.div>
            <motion.div
              className="text-white rounded p-1 w-16 h-16 md:p-2 md:w-24 md:h-24 bg-transparent flex justify-center items-center"
              whileHover={{
                scale: 1.1,
                originX: 0,
                boxShadow:
                  "0px 0px 8px rgb(2, 143, 224)",
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 10,
                },
              }}
              style={{
                boxShadow:
                  "0px 0px 5px #cbd5e1",
              }}
            >
              <img
                src="/skills/python.png"
                alt=""
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */
}
{
  /* <div className="pb-5 h-full">
        <div className="h-full flex flex-col md:flex-row m-auto w-4/5 gap-16">
          <div
            className="flex flex-1 flex-col items-center gap-5 p-5"
            // style={{
            //   boxShadow: "0px 0px 6px #cbd5e1",
            // }}
          >
            <img
              src="/skills/react.png"
              className="h-44"
              alt=""
            />
            <Reveal>
              <h1 className="text-3xl font-bold">Frontend</h1>
            </Reveal>
            <Reveal>
              <p className="text-lg font-thin text-center">
                Proficient in frontend development
                and design, possessing a strong
                command of design tools such as
                Figma, Webflow, and Canva.
              </p>
            </Reveal>
          </div>
          <div className="flex flex-1 flex-col items-center gap-5 p-5">
            <img
              src="/skills/server.png"
              className="h-44"
              alt=""
            />
            <Reveal>
              <h1 className="text-3xl font-bold">Server</h1>
            </Reveal>
            <Reveal>
              <p className="text-lg font-thin text-center">
                Experienced in server-side
                development with a solid grasp of
                GCP for smooth and efficient
                deployments.
              </p>
            </Reveal>
          </div>
          <div className="flex flex-1 flex-col items-center gap-5 p-5">
            <img
              src="/skills/database.png"
              className="h-44"
              alt=""
            />
            <Reveal>
              <h1 className="text-3xl font-bold">Database</h1>
            </Reveal>
            <Reveal>
              <p className="text-lg font-thin text-center">
                Proficient in database management
                with expertise in both relational
                and non-relational databases.
              </p>
            </Reveal>
          </div>
        </div>
      </div> */
}
