import "./App.css";
import { useState } from "react";
import { Navbar } from "./Components/Navbar";
import { Hero } from "./Components/Hero";
import { About } from "./Components/About";
import { Contact } from "./Components/Contact";
import { Cursor } from "./Components/Cursor";
import { Skills } from "./Components/Skills";
import Project from "./Components/Project";
import ProjectDetails from "./Components/ProjectDetails";

function App() {
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  return (
    <div className="">
      <Cursor />
      <Navbar />
      <Hero />
      <About />
      <Project openModal={openModal} setOpenModal={setOpenModal} />
      {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
      }
      <Skills />
      <Contact />
    </div>
  );
}

export default App;
