import { motion } from "framer-motion";
import { Reveal } from "./Reveal";

export const Hero = () => {
  const Items = ["about", "projects","skills", "contact"];

  const textVariants = {
    scrollButton: {
      opacity: 0,
      y: 10,
      transition: {
        duration: 2,
        repeat: Infinity,
      },
    },
  };

  return (
    <div
      className="h-full overflow-hidden relative flex flex-row"
      id="Home"
    >
      <div className="h-full w-full flex justify-center items-center md:justify-start md:w-4/5 m-auto">
        <div className="textContainer w-full h-full flex flex-col justify-center gap-3 md:gap-6">
          <div className="border-8 border-slate-500 pl-10 py-10 my-10 mx-5 md:mx-0 gap-5 flex flex-row">
            <div className="gap-5 flex flex-col">
              <div className="">
                <p
                  className="text-xs md:text-lg"
                  style={{
                    fontFamily: "Style Script",
                    color:
                      "rgb(235, 235, 235,0.7)",
                  }}
                >
                  &#8826; h1 &#8827;
                </p>
                <div className="ml-5">
                  <Reveal>
                    <h1
                      className="text-5xl sm:text-7xl md:mr-auto md:text-8xl font-bold"
                      style={{
                        textShadow: "0 0 1px ",
                      }}
                    >
                      Hi,
                    </h1>
                  </Reveal>
                  <Reveal>
                    <h1
                      className="text-5xl sm:text-7xl md:mr-auto md:text-8xl font-bold"
                      style={{
                        textShadow: "0 0 1px ",
                      }}
                    >
                      I'm{" "}
                      <span
                        className="tracking-wide"
                        style={{
                          color: "#ffffff",
                          textShadow:
                            "5px 5px 0 #001524, 6px 6px 0 #ffffff",
                        }}
                      >
                        Dev
                      </span>
                    </h1>
                  </Reveal>
                  <Reveal>
                    <h1
                      className="text-5xl sm:text-7xl md:mr-auto md:text-8xl font-bold"
                      style={{
                        textShadow: "0 0 1px ",
                      }}
                    >
                      A Developer
                    </h1>
                  </Reveal>
                </div>
                <p
                  className="text-xs md:text-lg"
                  style={{
                    fontFamily: "Style Script",
                    color:
                      "rgb(235, 235, 235,0.7)",
                  }}
                >
                  &#8826; /h1 &#8827;
                </p>
              </div>
              <div className="">
                <p
                  className="text-xs md:text-lg"
                  style={{
                    fontFamily: "Style Script",
                    color:
                      "rgb(235, 235, 235,0.7)",
                  }}
                >
                  &#8826; p &#8827;
                </p>
                <Reveal>
                  <h3
                    className="text-lg sm:text-xl md:text-2xl ml-5 font-thin"
                    style={{
                      color:
                        "rgb(235, 235, 235,0.5)",
                    }}
                  >
                    Crafting digital symphonies through code.
                  </h3>
                </Reveal>
                <p
                  className="text-xs md:text-lg"
                  style={{
                    fontFamily: "Style Script",
                    color:
                      "rgb(235, 235, 235,0.7)",
                  }}
                >
                  &#8826; /p &#8827;
                </p>
              </div>
              <motion.img
                className="w-12"
                variants={textVariants}
                animate="scrollButton"
                src="/images/scroll.png"
                alt=""
              />
            </div>
            <div className="nav-links flex flex-col font-bold ml-auto mt-auto">
              {Items.map((item) => {
                return (
                  <div className="bg-slate-500 py-5 px-2 rotate-180 box-border">
                    <a
                      href={`#${item}`}
                      key={item}
                      className="text-md md:text-xl"
                      style={{
                          writingMode: "vertical-lr", 
                        }
                      }
                    >
                      {item}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};