import React from 'react';
import { Reveal } from '../Reveal';
import { FaGithub } from "react-icons/fa";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
const ProjectCards = ({ project, setOpenModal }) => {
  return (
    <div className="w-[330px] h-full bg-card cursor-pointer rounded-lg shadow-md shadow-black overflow-hidden p-6 flex flex-col items-center gap-4 transition duration-300 ease-in-out hover:transform hover:-translate-y-5 hover:shadow-2xl hover:shadow-black hover:brightness-110" 
    onClick={() => setOpenModal({ state: true, project: project })}
    >
      <div className="flex flex-col gap-2">
        <img className="w-full h-[180px] bg-white rounded-lg shadow-md" src={project.image} alt="Project" />
        <div className="w-full flex flex-wrap gap-2 items-center mt-2">
          {project.tags?.map((tag, index) => (
            <span key={index} className="text-xs font-normal text-primary bg-slate-600 px-2 rounded-full">{tag}</span>
          ))}
        </div>
        <div className="w-full flex flex-col gap-0 px-2">
          <Reveal>
          <div className="text-lg font-semibold overflow-ellipsis whitespace-wrap max-w-full line-clamp-2">{project.title}</div>
          </Reveal>
          <Reveal>
          <div className="text-xs font-normal text-text_secondary-80 mt-1">{project.date}</div>
          </Reveal>
          <Reveal>
          <div className="text-sm font-normal overflow-ellipsis line-clamp-3 mt-2">{project.description}</div>
          </Reveal>
        </div>
      </div>
      <div className="flex flex-row items-center w-full">
        <div className="mr-auto">
          <a href={project.github} target="_blank" rel="noreferrer" className="text-primary font-semibold text-2xl mr-auto"><FaGithub/></a>
        </div>
        {project.projectLink !== "" && (
          <div className="ml-auto text-primary text-[15px] border-[1px] p-1 border-slate-800 rounded-lg">
            <a href={project.projectLink} target="_blank" rel="noreferrer">View <IoArrowForwardCircleOutline className='inline text-lg' /></a>
          </div>
         )
        }
      </div>
    </div>
  );
};

export default ProjectCards;
