export const project = [
  {
    id: 2,
    title: "Wordle: Word guessing game.",
    date: "Mar 2024 - April 2024",
    description:
      "A simple yet addictive daily word puzzle game. Players guess a secret five-letter word, receiving feedback on correct letters and their positions. With limited guesses, strategy and word knowledge are key.",
    image:
      "/projects/wordle.png",
    tags: [
      "ReactJs",
      "TailwindCss",
      "Node",
      "Express",
      "MongoDb",
      "HTML/CSS",
      "JavaScript",
    ],
    category: "web app",
    github: "http://github.com/Dev240204/Wordle",
    projectLink: "https://dev-wordle.vercel.app/",

  },
  {
    id: 2,
    title: "Ecomnia: The Ecommerce Analyzer",
    date: "Oct 2023 - Dec 2023",
    description:
      "Ecomnia is an advanced ecommerce analysis tool with an dual website analysis, and powerful Random Forest Regression for future price predictions while graphical visualization offers a comprehensive view of product data within websites and machine learning for a robust and secure ecommerce analytics solution.",
    image:
      "/projects/ecomnia.png",
    tags: [
      "Django",
      "SQLite",
      "Web Scraping",
      "HTML/CSS",
      "JavaScript",
      "Bootstrap",
      "Python",
      "Seaborn",
      "Matplotlib",
      "Random Forest Regression",
    ],
    category: "machine learning",
    github: "https://github.com/Dev240204/Ecomnia",
    projectLink: ""
  },
  {
    id: 0,
    title: "Stockwise: Financial Stocks Advisory",
    date: "Apr 2023 - June 2023",
    description:
      "StockWise goes beyond conventional financial platforms by offering personalized stock market suggestions and pinpointing strategic windows of opportunity for users. Deep Evolutionary Strategy is used for financial analysis of stocks for financial trading.",
    image:
      "/projects/stockwise.png",
    tags: [
      "Node",
      "Express",
      "MongoDb",
      "Flask Apis",
      "ReactJs",
      "HTML/CSS",
      "JavaScript",
      "Python",
      "Deep Evolutionary Strategy",
    ],
    category: "machine learning",
    github: "https://github.com/Dev240204/Stockwise",
    projectLink: ""
  },
  {
    id: 1,
    title: "CampOutdoors: Campsite Reservation Platform ",
    date: "Mar 2023  - May 2023",
    description:
      "It is a Campsite Booking platform featuring dual interfaces: one for users to reserve campsites and another for campsite owners to manage their listings, including location, price, and availability.It also has arial view of campsites using MapLibra Apis.",
    image:
      "/projects/camp-outdoors.png",
    tags: [
      "Node",
      "Express",
      "MongoDb",
      "MapLibra Apis",
      "HTML/CSS",
      "JavaScript",
      "Bootstrap",
    ],
    category: "web app",
    github: "http://github.com/Dev240204/Camp-Outdoors",
    projectLink: ""

  },
  // {
  //   id: 2,
  //   title: "Movie Database System",
  //   date: "Jun 2022 - Jul 2023",
  //   description:
  //     "Developed a robust Movie Database System using HTML, CSS, JavaScript, and SQL. This comprehensive platform features advanced search capabilities, responsive design, and user authentication. Demonstrates proficiency in web development, database management, and user-centric design for movie enthusiasts.",
  //   image:
  //     "https://repository-images.githubusercontent.com/617347036/1f6c0c84-12e5-4bf6-81c9-c7323a71d6d8",
  //   tags: ["HTML", "CSS", "JavaScript", "SQL"],
  //   category: "web app",
  //   github: "https://github.com/bhavya00000/MovieHub",
    
  // }
]

export const frontend = [
  {
    name: "Html",
    image: "/skills/html.png"
  },
  {
    name: "Css",
    image: "/skills/css.png"
  },
  {
    name: "JavaScript",
    image: "/skills/js.png"
  },
  {
    name: "ReactJs",
    image: "/skills/react.png"
  },
  {
    name: "NextJs",
    image: "/skills/nextjs.png"
  },
  {
    name: "TailwindCss",
    image: "/skills/tailwindcss.png"
  },
]

export const backend = [
  {
    name: "NodeJs",
    image: "/skills/node.png"
  },
  {
    name: "MongoDb",
    image: "/skills/mongodb.png"
  },
  {
    name: "MySql",
    image: "/skills/mysql.png"
  },
  {
    name : "PostgreSQL",
    image: "/skills/postresSql.png"
  },
  {
    name: "Django",
    image: "/skills/django.png"
  },
]
export const languages = [
  {
    name: "Python",
    image: "/skills/python.png"
  },
  {
    name: "Java",
    image: "/skills/java.png"
  },
  {
    name: "C++",
    image: "/skills/c++.png"
  },
  {
    name: "C",
    image: "/skills/c.png"
  },
]

export const tools = [
  {
    name: "Git",
    image: "/skills/git.png"
  },
  {
    name: "Figma",
    image: "/skills/figma.png"
  },
  {
    name: "VS Code",
    image: "/skills/vscode.png"
  },
  {
    name: "PyCharm",
    image: "/skills/pycharm.png"
  },
  {
    name : "Docker",
    image: "/skills/docker.png"
  },
  {
    name: "Vercel",
    image: "/skills/vercel.png"
  },
  {
    name: "AWS",
    image: "/skills/aws.png"
  },
  {
    name: "GCP",
    image: "/skills/gcp.png"
  },
]