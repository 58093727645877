import { motion } from "framer-motion";
import { useState } from "react";
import GitHub from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Items = ["about", "projects", "skills", "contact"];

export const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="navbar h-20 bg-transparent text-white" style={{}}>
      {/* {Sidebar}
        <Sidebar /> */}
      {/* Navbar */}
      <div className="wrapper h-full flex flex-row items-center justify-between w-4/5 m-auto">
        <motion.span
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          className="logo z-20"
        >
          <a href="/">
            <img
              src="/images/image.png"
              alt="Logo"
              className="w-24"
            />
          </a>
        </motion.span>
        <div className="flex-row items-center gap-24 hidden md:flex">
          <div className="social md:flex flex-row gap-5 hidden">
            <a href="https://www.instagram.com/devpanchal_24" target="_blank" rel="noreferrer">
              {" "}
              <InstagramIcon></InstagramIcon>
            </a>
            <a href="https://github.com/Dev240204" target="_blank" rel="noreferrer">
              {" "}
              <GitHub></GitHub>
            </a>
            <a href="https://www.linkedin.com/in/dev-panchal-9a4599226" target="_blank" rel="noreferrer">
              {" "}
              <LinkedInIcon></LinkedInIcon>
            </a>
            <a href="https://twitter.com/Dvl_0204" target="_blank" rel="noreferrer">
              {" "}
              <TwitterIcon></TwitterIcon>
            </a>
          </div>
        </div>
        <div className="md:hidden">
          <button
            className="w-10 h-8 flex flex-col justify-between z-50 relative"
            onClick={() => setOpen(!open)}
          >
            <div
              className={
                "w-10 h-1 bg-white rounded"
              }
            ></div>
            <div
              className={
               "w-10 h-1 bg-white rounded"
              }
            ></div>
            <div
              className={
               "w-10 h-1 bg-white rounded"
              }
            ></div>
          </button>
          {/* Menu list */}
          {open && (
            <motion.div
              className="absolute top-0 left-0 flex flex-col items-center justify-center text-2xl sm:text-4xl w-screen h-1/2 text-white z-10 pt-20"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              style={{ backgroundColor: "#000000" }}
            >
              {Items.map((item) => {
                return (
                  <div className="flex flex-col w-full h-full justify-center items-center border-b-2 border-slate-700">
                    <a
                      href={`#${item}`}
                      key={item}
                      className="text-md md:text-xl mb-1"
                    >
                      {item}
                    </a>
                  </div>
                );
              })}
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};
