import React from "react";
import { motion } from "framer-motion";
import { Reveal } from "./Reveal";
import { backend, frontend, languages, tools, } from "../data";

export const Skills = () => {
  return (
    <div className="relative h-full" id="skills">
      <div className="pb-5 h-full">
        <div className="h-full flex flex-row m-auto w-4/5 gap-16">
          <div className="md:flex flex-col gap-1 md:gap-2 h-full mt-20">
            <Reveal>
              <h1 className="text-5xl sm:text-7xl md:mr-auto md:text-8xl font-extrabold">
                &#8826;skills&#8827;
              </h1>
            </Reveal>
            <Reveal>
              <h2
                className="text-lg sm:text-xl md:text-2xl font-light md:mr-auto my-5"
                style={{ color: "#ffffffaa" }}
              >
                Dynamic skill set covering the
                wide spectrum of web development
              </h2>
            </Reveal>

            <div className="flex flex-col lg:flex-row flex-wrap w-full gap-10 my-5 h-fit">
              <div className="flex flex-col md:flex-row gap-8">
                <div
                  className="flex flex-col gap-5 flex-1 items-center justify-start p-8 h-fit rounded-lg shadow-lg shadow-black"
                >
                  <Reveal>
                    <h1 className="text-2xl text-center font-bold">
                      Frontend
                    </h1>
                  </Reveal>
                  <div className="flex flex-row gap-3 flex-wrap justify-center">
                    {frontend.map(
                      (skill, index) => (
                        <motion.div
                          className="text-white rounded-2xl p-[10px] w-fit bg-transparent flex gap-2 justify-center items-center"
                          style={{
                            boxShadow:
                              "0px 0px 2px #cbd5e1",
                          }}
                        >
                           <img
                            src={skill.image}
                            alt=""
                            className="w-8 h-8"
                          />
                          <h1>{skill.name}</h1>
                        </motion.div>
                      )
                    )}
                  </div>
                </div>
                <div
                  className="flex flex-col flex-1 gap-5 items-center justify-start p-8 h-fit rounded-lg shadow-lg shadow-black"
                >
                  <Reveal>
                    <h1 className="text-2xl text-center font-bold">
                      Tools
                    </h1>
                  </Reveal>
                  <div className="flex flex-row gap-3 flex-wrap justify-center">
                    {tools.map(
                      (skill, index) => (
                        <motion.div
                          className="text-white rounded-2xl p-[10px] w-fit gap-2 bg-transparent flex justify-center items-center"
                          style={{
                            boxShadow:
                              "0px 0px 2px #cbd5e1",
                          }}
                        >
                           <img
                            src={skill.image}
                            alt=""
                            className="w-8 h-8"
                          />
                          <h1>{skill.name}</h1>
                        </motion.div>
                      )
                    )}
                  </div>{" "}
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-8">
                <div
                  className="flex flex-col flex-1 gap-5 shadow-lg shadow-black items-center justify-start p-8 h-fit rounded-lg"
                >
                  <Reveal>
                    <h1 className="text-2xl text-center font-bold">
                      Server
                    </h1>
                  </Reveal>
                  <div className="flex flex-row gap-3 flex-wrap justify-center">
                    {backend.map(
                      (skill, index) => (
                        <motion.div
                          className="text-white rounded-2xl p-[10px] gap-2 w-fit bg-transparent flex justify-center items-center"
                          style={{
                            boxShadow:
                              "0px 0px 2px #cbd5e1",
                          }}
                        >
                           <img
                            src={skill.image}
                            alt=""
                            className="w-8 h-8"
                          />
                          <h1>{skill.name}</h1>
                        </motion.div>
                      )
                    )}
                  </div>{" "}
                </div>
                <div
                  className="flex flex-col flex-1 gap-5 shadow-lg shadow-black items-center justify-start p-8 h-fit rounded-lg"
                >
                  <Reveal>
                    <h1 className="text-2xl text-center font-bold">
                      Programming Languages
                    </h1>
                  </Reveal>
                  <div className="flex flex-row gap-3 flex-wrap justify-center">
                    {languages.map(
                      (skill, index) => (
                        <motion.div
                          className="text-white rounded-2xl p-[10px] gap-2 w-fit bg-transparent flex justify-center items-center"
                          style={{
                            boxShadow:
                              "0px 0px 2px #cbd5e1",
                          }}
                        >
                          <img
                            src={skill.image}
                            alt=""
                            className="w-8 h-8"
                          />
                          <h1>{skill.name}</h1>
                        </motion.div>
                      )
                    )}
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
