import { useState, useEffect } from "react";
import { motion } from "framer-motion";

export const Cursor = () => {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const mouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener(
      "mousemove",
      mouseMove
    );

    return () => {
      window.removeEventListener(
        "mousemove",
        mouseMove
      );
    };
  },[]);
  return (
    <motion.div
      className="hidden cursor w-12 rounded-full h-12 border-2 border-slate-700 fixed lg:block lg:z-50"
      animate={{ x: position.x, y: position.y }}
    ></motion.div>
  );
};
