import React, { useEffect, useRef } from "react";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion";

export const Reveal = ({
  children,
  width = "fit-content",
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref,{triggerOnce:false});
  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
      slideControls.start("visible");
    }
  }, [isInView]);
  return (
    <div
      style={{
        position: "relative",
        width,
        overflow: "hidden",
      }}
      ref={ref}
    >
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{
          duration: 0.5,
          delay: 0.25,
        }}
        initial="hidden"
        animate={mainControls}
      >
        {children}
      </motion.div>
      <motion.div
        className="absolute top-1 bottom-1 left-0 right-0 md:z-20 bg-slate-500"
        variants={{
          hidden: { left: 0 },
          visible: { left: "100%" },
        }}
        initial="hidden"
        animate={slideControls}
        transition={{ duration: 0.5, ease: "easeIn"}}
      />
    </div>
  );
};
