import React from 'react';
import { CloseRounded, GitHub, LinkedIn } from '@mui/icons-material';
import { Modal } from '@mui/material';

const Index = ({ openModal, setOpenModal }) => {
  const project = openModal?.project;
  return (
    <Modal open={true} onClose={() => setOpenModal({ state: false, project: null })}>
      <div className="w-full h-full absolute top-0 left-0 flex items-top justify-center bg-black bg-opacity-70 overflow-y-scroll transition duration-500 ease">
        <div className="w-full max-w-3xl rounded-2xl my-12 mx-3 h-min bg-slate-950 flex flex-col p-5 relative">
          <CloseRounded
            className="absolute top-3 right-5 cursor-pointer"
            onClick={() => setOpenModal({ state: false, project: null })}
          />
          <img className="w-full mt-8 h-[350px] md:h-96 lg:h-[400px] xl:h-[420px] object-cover rounded-lg" src={project?.image} alt="Project" style={{boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)"}} />
          <div className="mt-6">
            <h2 className="text-2xl font-semibold mx-[6px] mt-[8px] ">{project?.title}</h2>
            <p className="text-sm font-medium mx-[6px] my-[2px]">{project.date}</p>
            <div className="flex flex-wrap gap-2 mt-2">
              {project?.tags.map((tag, index) => (
                <div key={index} className=" text-xs md:text-sm font-normal text-primary bg-slate-700 px-2 py-1 rounded-full">{tag}</div>
              ))}
            </div>
            <p className="mt-4 mx-[6px] text-sm md:text-[16px] font-normal ">{project?.description}</p>
            {project.member && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold ">Members</h3>
                <div className="mt-2 space-y-2">
                  {project?.member.map((member, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <img className="w-12 h-12 rounded-full bg-white shadow-md border-2 border-white" src={member.img} alt="Member Avatar" />
                      <span className="text-base font-medium ">{member.name}</span>
                      <a href={member.github} target="_blank" rel="noopener noreferrer" className="text-primary"><GitHub /></a>
                      <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="text-primary"><LinkedIn /></a>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="mt-4 flex justify-end gap-4">
              <a href={project?.github} target="_blank" rel="noopener noreferrer" className="bg-slate-900 w-full text-center px-4 py-2 rounded-lg text-sm hover:bg-slate-500 hover:transition-all hover:ease-in md:text-[16px] font-semibold">View Code</a>
              {project?.projectLink !== "" && (
                <a href={project?.projectLink} target="_blank" rel="noopener noreferrer" className="bg-slate-900 w-full text-center px-4 py-2 rounded-lg text-sm hover:bg-slate-500 hover:transition-all hover:ease-in md:text-[16px] font-semibold">View Live App</a>
              )
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Index;
