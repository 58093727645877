import { motion, useInView } from "framer-motion";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Reveal } from "./Reveal";
import React from 'react';
import { FaTwitter, FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { Toaster, toast } from 'sonner'


export const Contact = () => {
  const ref = useRef();
  const formRef = useRef();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  const isInView = useInView(ref);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ecl0kmq",
        "template_of3dgbj",
        formRef.current,
        {
          publicKey: "6BybDYQPgTv4BwWV1",
        }
      )
      .then(() => {
         toast.success("Message Sent Successfully")
        }
      )
      .catch((err) => {
        toast.error("Message not sent")
        }
      );
    setFormValues({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div
      className="relative h-screen flex flex-col mt-28"
      id="contact"
    >
      <Toaster richColors closeButton position="top-center"/>
      <div
        className="w-full pb-5"
      >
        <div
          ref={ref}
          className="h-full w-4/5 m-auto md:flex flex-row gap-12 items-center"
        >
          <div className="flex flex-col flex-1">
            <Reveal>
              <h1
                className="text-6xl sm:text-7xl md:mr-auto md:text-8xl font-extrabold"
                style={{ color: "#ffffff" }}
              >
                &#8826;contact&#8827;
              </h1>
            </Reveal>
            <Reveal>
              <h2
                className="text-lg sm:text-xl md:text-2xl font-light"
                style={{ color: "#ffffffaa" }}
              >
                Get in touch with me via social
                media or email
              </h2>
            </Reveal>
            <div className="w-3/4 grid grid-cols-2 gap-6 mt-10">
              <Reveal>
                <div className="img-container flex flex-row justify-start items-center text-blue-500 font-semibold w-48">
                  <img
                    src="/images/linkedin.png"
                    className="inline w-6 sm:w-8 md:w-10 mr-2 rounded-full"
                    alt=""
                  />
                  <motion.a
                    href="https://www.linkedin.com/in/dev-panchal-9a4599226"
                    className="text-md sm:text-lg md:text-xl"
                    target="_blank"
                    whileHover={{
                      scale: 1.1,
                      originX: 0,
                      textShadow:
                        "0 0 1px rgb(0,0,0)",
                    }}
                  >
                    LinkedIn
                  </motion.a>
                </div>
              </Reveal>
              <Reveal>
                <div className="img-container flex flex-row justify-start items-center text-pink-600 font-semibold w-48">
                  <img
                    src="/images/instagram.png"
                    className="inline w-6 sm:w-8 md:w-10 mr-2 rounded-full"
                    alt=""
                  />
                  <motion.a
                    href="https://www.instagram.com/devpanchal_24"
                    target="_blank"
                    className="text-md sm:text-lg md:text-xl"
                    whileHover={{
                      scale: 1.1,
                      originX: 0,
                      textShadow:
                        "0 0 1px rgb(0,0,0)",
                    }}
                  >
                    Instagram
                  </motion.a>
                </div>
              </Reveal>
              <Reveal>
                <div className="img-container flex flex-row justify-start items-center text-blue-400 font-semibold w-48">
                  <img
                    src="/images/twitter.png"
                    className="inline w-6 sm:w-8 md:w-10 mr-2 object-cover"
                    alt=""
                  />
                  <motion.a
                    href="https://twitter.com/Dvl_0204"
                    target="_blank"
                    className="text-md sm:text-lg md:text-xl"
                    whileHover={{
                      scale: 1.1,
                      originX: 0,
                      textShadow:
                        "0 0 1px rgb(0,0,0)",
                    }}
                  >
                    Twitter
                  </motion.a>
                </div>
              </Reveal>
              <Reveal>
                <div className="img-container flex flex-row justify-start items-center text-white font-semibold w-48">
                  <img
                    src="/images/github.png"
                    className="inline w-6 sm:w-8 md:w-10 mr-2 rounded-full"
                    alt=""
                  />
                  <motion.a
                    href="https://github.com/Dev240204"
                    target="_blank"
                    className="text-md sm:text-lg md:text-xl"
                    whileHover={{
                      scale: 1.1,
                      originX: 0,
                      textShadow:
                        "0 0 1px rgb(0,0,0)",
                    }}
                  >
                    GitHub
                  </motion.a>
                </div>
              </Reveal>
            </div>
          </div>
          <div className="svgContainer hidden md:flex flex-1">
            <motion.div
              className="phoneSvg m-auto"
              style={{ stroke: "white" }}
              initial={{ opacity: 1 }}
              whileInView={{ opacity: 1 }}
              transition={{
                delay: 3,
                duration: 1,
              }}
            >
              <svg
                width="450px"
                height="450px"
                viewBox="0 0 32.666 32.666"
              >
                <motion.path
                  strokeWidth={0.2}
                  fill="none"
                  initial={{ pathLength: 0 }}
                  animate={
                    isInView && { pathLength: 1 }
                  }
                  transition={{ duration: 3 }}
                  d="M28.189,16.504h-1.666c0-5.437-4.422-9.858-9.856-9.858l-0.001-1.664C23.021,4.979,28.189,10.149,28.189,16.504z
                M16.666,7.856L16.665,9.52c3.853,0,6.983,3.133,6.981,6.983l1.666-0.001C25.312,11.735,21.436,7.856,16.666,7.856z M16.333,0
                C7.326,0,0,7.326,0,16.334c0,9.006,7.326,16.332,16.333,16.332c0.557,0,1.007-0.45,1.007-1.006c0-0.559-0.45-1.01-1.007-1.01
                c-7.896,0-14.318-6.424-14.318-14.316c0-7.896,6.422-14.319,14.318-14.319c7.896,0,14.317,6.424,14.317,14.319
                c0,3.299-1.756,6.568-4.269,7.954c-0.913,0.502-1.903,0.751-2.959,0.761c0.634-0.377,1.183-0.887,1.591-1.529
                c0.08-0.121,0.186-0.228,0.238-0.359c0.328-0.789,0.357-1.684,0.555-2.518c0.243-1.064-4.658-3.143-5.084-1.814
                c-0.154,0.492-0.39,2.048-0.699,2.458c-0.275,0.366-0.953,0.192-1.377-0.168c-1.117-0.952-2.364-2.351-3.458-3.457l0.002-0.001
                c-0.028-0.029-0.062-0.061-0.092-0.092c-0.031-0.029-0.062-0.062-0.093-0.092v0.002c-1.106-1.096-2.506-2.34-3.457-3.459
                c-0.36-0.424-0.534-1.102-0.168-1.377c0.41-0.311,1.966-0.543,2.458-0.699c1.326-0.424-0.75-5.328-1.816-5.084
                c-0.832,0.195-1.727,0.227-2.516,0.553c-0.134,0.057-0.238,0.16-0.359,0.24c-2.799,1.774-3.16,6.082-0.428,9.292
                c1.041,1.228,2.127,2.416,3.245,3.576l-0.006,0.004c0.031,0.031,0.063,0.06,0.095,0.09c0.03,0.031,0.059,0.062,0.088,0.095
                l0.006-0.006c1.16,1.118,2.535,2.765,4.769,4.255c4.703,3.141,8.312,2.264,10.438,1.098c3.67-2.021,5.312-6.338,5.312-9.719
                C32.666,7.326,25.339,0,16.333,0z"
                />
              </svg>
            </motion.div>
          </div>
        </div>
      </div>
      <div
        className="form-cont w-4/5 m-auto mt-24 flex flex-col"
      >
        <h1 className="text-2xl sm:text-3xl md:text-4xl mb-3 md:mb-5">
          Send me an Email
        </h1>
        <motion.form
          ref={formRef}
          initial={{ opacity: 1 }}
          whileInView={{ opacity: 1 }}
          onSubmit={sendEmail}
        >
          <div className="formarea flex flex-col w-full gap-5">
            <div className="flex flex-col md:flex-row w-full gap-5">
              <div className="name-email flex flex-col gap-5 flex-1">
                <div className="flex flex-col">
                  <label
                    htmlFor="name"
                    className="text-md sm:text-lg md:text-xl"
                  >
                    Name
                  </label>
                  <input
                    className="p-3 bg-transparent border-2 border-slate-400 rounded-md"
                    type="text"
                    required
                    name="name"
                    placeholder="Name"
                    id="name"
                    value={formValues.name}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="email"
                    className="text-md sm:text-lg md:text-xl"
                  >
                    Email
                  </label>
                  <input
                    className="p-3 bg-transparent border-2 border-slate-400 rounded-md"
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                    value={formValues.email}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="textarea flex flex-1 flex-col">
                <label
                  htmlFor="message"
                  className="text-md sm:text-lg md:text-xl"
                >
                  Message
                </label>
                <textarea
                  className="p-3 bg-transparent border-2 border-slate-400 rounded-md"
                  rows={5}
                  cols={50}
                  required
                  name="message"
                  placeholder="Message"
                  value={formValues.message}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      message: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
            <button className="mb-10 p-3 bg-transparent border-2 border-slate-500 rounded-md font-bold text-lg ml-auto">
              Submit
            </button>
          </div>
        </motion.form>
      </div>
      <footer className="relative h-full py-8 w-3/4 m-auto">
      <div className="container mx-auto flex flex-row items-center justify-between gap-4">
        <div className="flex gap-4 flex-col items-center">
        <a href="#Home">
            <img
              src="/images/image.png"
              alt="Logo"
              className="w-24"
            />
          </a>
          <div className="flex flex-row gap-4">
            <a href='https://twitter.com/Dvl_0204' target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-dark transition duration-300"><FaTwitter /></a>
            <a href='https://github.com/Dev240204' target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-dark transition duration-300"><FaGithub /></a>
            <a href='https://www.linkedin.com/in/dev-panchal-9a4599226' target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-dark transition duration-300"><FaLinkedin /></a>
            <a href='https://www.instagram.com/devpanchal_24' target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-dark transition duration-300"><FaInstagram /></a>
          </div>
        </div>
        <p className="text-sm md:text-[16px] text-slate-300 text-center">&copy; 2024 Dev Panchal. All rights reserved.</p>
      </div>
    </footer>
    </div>
  );
};
